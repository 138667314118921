.bg-surface {
    @apply bg-white dark:bg-dark_surface
}

.title-on-surface {
    @apply text-dark_surface dark:text-gray-100
}

.subtitle-on-surface {
    @apply text-gray-700 dark:text-gray-400
}

.theme-landing-bg {
    background-color: #fcf5eb;
}

/*:root {*/
/*    !*--theme-landing-bg: #fcf5eb;*!*/
/*    --theme-landing-bg: ;*/
/*}*/
