.swiper-pagination-bullet {
    @apply bg-primary-300 !important
}

.swiper-pagination-bullet-active {
    @apply bg-primary-500 !important
}

/* Swiper.js */
:root {
    --swiper-theme-color: theme('colors.primary.500') !important;
}
