.unviewed-stories {
    position: relative;
    border-radius: 50%;
    padding: 4px;
    background-image: linear-gradient(to right top, #ffc600 20%, #ff0040, #e600cc 80%);
    cursor: pointer;
}
.unviewed-stories::before {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    right: 2px;
    bottom: 2px;
    background: #fff;
    border-radius: 50%;
    z-index: 1;
}
.unviewed-stories img {
    position: relative;
    z-index: 2;
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.unviewed-stories > .profile-avatar img {
    position: relative;
    z-index: 2;
    display: block;
    width: unset;
    height: unset;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}