@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    overflow: hidden !important;
    overscroll-behavior: none;
    @apply transparent-scroll;
}

body {
    overflow: hidden !important;
    margin: 0;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #e0e0e0 transparent;
}

.dark {
    * {
        scrollbar-width: auto;
        scrollbar-color: rgb(10 10 10) transparent;
    }
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #eeeeee;
    border-radius: 8px;
}

.transparent-scroll::-webkit-scrollbar {
    display: none;
}

.MuiDialog-paper {
    @apply sm:rounded-xl;
}

/* Speed Dial Action */
.MuiSpeedDialAction-staticTooltipLabel {
    @apply text-black text-xl font-medium shadow-none bg-transparent;
}

.MuiFab-primary {
    @apply bg-primary-500;
}

.bottom-safe-area {
    padding-bottom: env(safe-area-inset-bottom, 0px)
}

.bottom-safe-area-with-nav {
    padding-bottom: calc(env(safe-area-inset-bottom, 0px) + 56px) !important;
}

.textcontainer {padding:40px 0;text-align:center;}
.particletext {
    text-align: center;
    font-size: 48px;
    position: relative;

    &.bubbles {
        > .particle {
            opacity: 0;
            position: absolute;
            background-color: rgba(33, 150, 243, 0.5);
            animation: bubbles 3s ease-in infinite;
            border-radius: 100%;
        }
    }

    &.hearts {
        > .particle {
            opacity: 0;
            position: absolute;
            background-color: rgba(204, 42, 93, 1);
            animation: hearts 3s ease-in infinite;

            &:before, &:after {
                position: absolute;
                content: '';
                border-radius: 100px;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background-color: rgba(204, 42, 93, 1);
            }

            &:before {
                transform: translateX(-50%);
            }

            &:after {
                transform: translateY(-50%);
            }
        }
    }

    &.lines {
        > .particle {
            position: absolute;
            background-color: rgba(244, 67, 54, 0.5);
            animation: lines 3s linear infinite;
        }
    }

    &.confetti {
        > .particle {
            opacity: 0;
            position: absolute;
            animation: confetti 3s ease-in infinite;

            &.c1 {
                background-color: rgba(76, 175, 80, 0.5);
            }

            &.c2 {
                background-color: rgba(156, 39, 176, 0.5);
            }
        }
    }

    &.fire {
        > .particle {
            position: absolute;
            background-color: rgba(255, 193, 7, 0.5);
            border-radius: 40px;
            border-top-right-radius: 0px;
            animation: fires 0.8s linear infinite;
            transform: rotate(-45deg);
            opacity: 0;

            &:before {
                position: absolute;
                content: '';
                top: 60%;
                left: 40%;
                transform: translate(-50%, -50%);
                width: 50%;
                height: 50%;
                border-radius: 40px;
                border-top-right-radius: 0px;
                background-color: rgba(251, 140, 0, 0.5);
            }
        }
    }

    &.sunbeams {
        > .particle {
            position: absolute;
            background-color: rgba(253, 216, 53, 0.5);
            animation: sunbeams 3s linear infinite;
        }
    }
}





@keyframes bubbles {
    0% {
        opacity: 0;
    }
    20% {
    opacity: 1;
        transform:translate(0, -20%);
    }
    100% {
        opacity: 0;
        transform:translate(0, -1000%);
    }
}
@keyframes hearts {
    0% {
        opacity: 0;
        transform:translate(0, 0%) rotate(45deg);
    }
    20% {
    opacity: 0.8;
        transform:translate(0, -20%) rotate(45deg);
    }
    100% {
        opacity: 0;
        transform:translate(0, -1000%) rotate(45deg);
    }
}
@keyframes lines {
    0%, 50%, 100% {
        transform:translateY(0%);
    }
    25% {
        transform:translateY(100%);
    }
    75% {
        transform:translateY(-100%);
    }
}
@keyframes confetti {
    0% {
        opacity:0;
        transform:translateY(0%) rotate(0deg);
    }
    10% {
        opacity:1;
    }
    35% {
        transform:translateY(-800%) rotate(270deg);
    }
    80% {
        opacity:1;
    }
    100% {
        opacity:0;
        transform:translateY(2000%) rotate(1440deg);
    }
}
@keyframes fires {
    0% {
        transform: rotate(-70deg) translateY(0%);
    }
    25% {
        transform: rotate(-20deg) translateY(-5%);
        opacity:1;
    }
    50%{
        transform: rotate(-70deg) translateY(-10%);
    }
    75% {
        transform: rotate(-20deg) translateY(-20%);
    }
    100% {
        transform: rotate(-70deg) translateY(-40%);
        opacity:1;
    }
}
@keyframes sunbeams {
    0% {
        transform:translateY(40%) rotate(0deg);
    }
    50% {
        transform:translateY(-40%) rotate(180deg);
    }
    100% {
        transform:translateY(40%) rotate(360deg);
    }
    0%,14%,17%,43%,53%,71%,80%,94%,100% {
        opacity:0;
    }
    6%,15%,24%,28%,48%,55%,78%,82%,99% {
        opacity:1;
    }
}
