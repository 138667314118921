.light {
  --md-sys-color-primary: rgb(139 74 99);
  --md-sys-color-surface-tint: rgb(139 74 99);
  --md-sys-color-on-primary: rgb(255 255 255);
  --md-sys-color-primary-container: rgb(255 217 228);
  --md-sys-color-on-primary-container: rgb(57 7 31);
  --md-sys-color-secondary: rgb(0 103 124);
  --md-sys-color-on-secondary: rgb(255 255 255);
  --md-sys-color-secondary-container: rgb(177 236 255);
  --md-sys-color-on-secondary-container: rgb(0 31 39);
  --md-sys-color-tertiary: rgb(125 86 54);
  --md-sys-color-on-tertiary: rgb(255 255 255);
  --md-sys-color-tertiary-container: rgb(255 220 195);
  --md-sys-color-on-tertiary-container: rgb(47 21 0);
  --md-sys-color-error: rgb(186 26 26);
  --md-sys-color-on-error: rgb(255 255 255);
  --md-sys-color-error-container: rgb(255 218 214);
  --md-sys-color-on-error-container: rgb(65 0 2);
  --md-sys-color-background: rgb(255 248 248);
  --md-sys-color-on-background: rgb(34 25 28);
  --md-sys-color-surface: rgb(255 248 248);
  --md-sys-color-on-surface: rgb(34 25 28);
  --md-sys-color-surface-variant: rgb(242 221 226);
  --md-sys-color-on-surface-variant: rgb(81 67 71);
  --md-sys-color-outline: rgb(131 115 119);
  --md-sys-color-outline-variant: rgb(213 194 198);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(55 46 49);
  --md-sys-color-inverse-on-surface: rgb(253 237 240);
  --md-sys-color-inverse-primary: rgb(255 176 203);
  --md-sys-color-primary-fixed: rgb(255 217 228);
  --md-sys-color-on-primary-fixed: rgb(57 7 31);
  --md-sys-color-primary-fixed-dim: rgb(255 176 203);
  --md-sys-color-on-primary-fixed-variant: rgb(111 51 75);
  --md-sys-color-secondary-fixed: rgb(177 236 255);
  --md-sys-color-on-secondary-fixed: rgb(0 31 39);
  --md-sys-color-secondary-fixed-dim: rgb(134 209 233);
  --md-sys-color-on-secondary-fixed-variant: rgb(0 78 94);
  --md-sys-color-tertiary-fixed: rgb(255 220 195);
  --md-sys-color-on-tertiary-fixed: rgb(47 21 0);
  --md-sys-color-tertiary-fixed-dim: rgb(240 188 149);
  --md-sys-color-on-tertiary-fixed-variant: rgb(98 63 33);
  --md-sys-color-surface-dim: rgb(230 214 217);
  --md-sys-color-surface-bright: rgb(255 248 248);
  --md-sys-color-surface-container-lowest: rgb(255 255 255);
  --md-sys-color-surface-container-low: rgb(255 240 243);
  --md-sys-color-surface-container: rgb(250 234 237);
  --md-sys-color-surface-container-high: rgb(244 228 231);
  --md-sys-color-surface-container-highest: rgb(239 223 226);
}
