.k-icon-button {
    @apply border border-gray-300 dark:border-neutral-800 w-9 min-w-9 overflow-visible !important
}

.scheduled-list {
    .space-x-1 {
        @apply gap-x-1 space-x-0 !important
    }

    position: unset !important;
}

.k-segmented {
    @apply gap-x-1 space-x-0 !important
}

.k-segmented:has(.k-segmented-button-subscribers) {
    .k-segmented button {
        @apply truncate break-keep px-4 !important
    }

    .k-segmented-button-subscribers.k-segmented-strong-button-active ~ span {
        width: calc(((100% - 76px) - 16px * 1) / 2) !important;
        transform: translateX(0) !important;
    }

    .k-segmented-button-free-previews.k-segmented-strong-button-active ~ span {
        width: calc(((100% - 76px) - 16px * 1) / 2) !important;
        transform: translateX(calc(100% + 76px + 12px)) !important;
    }

    .k-segmented-button-packs.k-segmented-strong-button-active ~ span {
        @apply w-20 transform translate-x-[156px] !important
    }

    .k-segmented-button-subscribers, .k-segmented-button-free-previews {
        @apply flex-1 w-0 !important
    }

    .k-segmented-button-packs {
        @apply flex-shrink-0 flex-grow-0 w-20 !important
    }
}

/*.k-segmented-strong-button-active {*/
/*    @apply flex-1 w-0 !important*/
/*}*/

.textarea-h-28 {
    height: 112px !important;
    @apply h-28 !important
}

/* Sheet */
.k-sheet {
}

.k-sheet > :not([hidden]) ~ :not([hidden]) {
        margin-left: 0 !important;
        margin-right: 0 !important;
}

.sheet-comments {
    height: calc(95vh - 92px - 72px - var(--k-safe-area-bottom) - var(--k-safe-area-top));
}

.theme-surface {
    @apply bg-ios-light-surface-1 dark:bg-neutral-900
}

.theme-surface-inverse {
    @apply bg-neutral-900 dark:bg-ios-light-surface-1
}

.theme-on-surface {
    @apply text-neutral-900 dark:text-neutral-200
}

.theme-on-surface-inverse {
    @apply text-neutral-50 dark:text-neutral-900
}

.theme-surface-bright {
    @apply bg-neutral-200 dark:bg-neutral-800
}

.theme-on-surface-bright {
    @apply text-neutral-800 dark:text-neutral-200
}

.theme-bg-avatar {
    @apply bg-neutral-400;
}

.theme-hairline {
    @apply hairline-gray-200 dark:hairline-neutral-600
}

.theme-image-placeholder {
    @apply bg-neutral-100 dark:bg-neutral-800
}

.theme-backdrop {
    @apply fixed z-40 w-full h-full left-0 top-0 backdrop-blur bg-neutral-700 bg-opacity-60 duration-400
}

.theme-text-on-navbar {
    @apply text-neutral-900/80 dark:text-neutral-50/80
}